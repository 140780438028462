(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("axios"), require("jsonp"), require("react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["axios", "jsonp", "react", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["p2pu-components"] = factory(require("axios"), require("jsonp"), require("react"), require("react-dom"));
	else
		root["p2pu-components"] = factory(root["axios"], root["jsonp"], root["react"], root["react-dom"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__742__, __WEBPACK_EXTERNAL_MODULE__458__, __WEBPACK_EXTERNAL_MODULE__155__, __WEBPACK_EXTERNAL_MODULE__514__) {
return 